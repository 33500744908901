import React from 'react';
import Nav from '../../Nav/Nav.tsx';
import Footer from '../../Footer/Footer';

const Contact = () => {
  return (
    <div>
      <Nav />
      <iframe 
        height="639"
        // allowTransparency="true"
        // frameborder="0"
        scrolling="no"
        style={{
          width: "80%",
          height: '130vh',
          padding: '6rem',
          border: 'none',
        }}
        src="https://tymorrison.wufoo.com/embed/z1dn8ev0ngr47k/">
        <a href="https://tymorrison.wufoo.com/forms/z1dn8ev0ngr47k/">

        </a>
      </iframe>
      <Footer />
    </div>
  )
};

export default Contact;
export const itemData = [
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Additions.JPG',
    title: 'Additions',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Additions2.JPG',
    title: 'Additions2',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Additions3.JPG',
    title: 'Additions3',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Additions4.JPG',
    title: 'Additions4',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Additions5.JPG',
    title: 'Additions5',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Bathrooms.JPG',
    title: 'Bathroom',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Bathrooms2.JPG',
    title: 'Bathroom2',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Custom+.JPG',
    title: 'Custom',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Custom2.JPG',
    title: 'Custom2',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Kitchens.JPG',
    title: 'Kitchen',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Kitchens2.JPG',
    title: 'Kitchen2',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Kitchens3.JPG',
    title: 'Kitchen3',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Kitchens4.JPG',
    title: 'Kitchen4',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Remodels.JPG',
    title: 'Remodel',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Remodels2.JPG',
    title: 'Remodel2',
  },
  {
    img: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/gallery/Remodels3.JPG',
    title: 'Remodel3',
  },
];
import React from 'react';
import { Link } from "react-router-dom";
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import './Banner.css';

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#FFF',
  backgroundColor: 'lightBlue',
  textDecoration: 'none',
  underline: 'none',
}));

const Banner = () => {
  return (
    <div className="banner">
      <Typography variant="h5" component="h5">
        Contact us about starting a project today!
      </Typography>
      <Button
        variant="contained"
        className="getStartedButton"
        sx={{
          backgroundColor: 'lightgray',
        }}
      >
        <Link
          to="/Contact"
          className="getStarted"
        >
          Get Started
        </Link>
      </Button>
    </div>
   
  );
}

export default Banner;
import React from 'react';
import { Typography } from '@mui/material';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <Typography variant="body1" component="p">
      Copyright &copy; Advantage Builders, Inc. 2023 
      </Typography>
    </div>
  );
};

export default Footer;
import React from 'react';
import { Typography } from '@mui/material';
import './Process.css';


const Processes = () => {
  return (
    <div className="process">
      <Typography variant="h3" component="h3" className="processHeader">
        The Advantage Builders' 6 Step Process:
      </Typography>
      <div className="processes">
        <div className="oddProcess">
          <div className="processText">
            <Typography variant="h4" component="h4">
              1. Consultation
            </Typography>
            <Typography variant="body1" component="p">
              You will meet with our Team leader, Craig Smith, who with 35 plus years of hands-on building experience, will discuss the “Scope of Work” for your project, and offer design tips, and important consideration. He will take detailed measurements, notes, and pictures, in order to develop the “Target Budget” for your project. You will be furnished with a reference list of previous clients that you may call, and even make arrangements to visit their homes, to see our work, and ask relevant questions.
            </Typography>
          </div>
          <div className="processImg">
            <img src="https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/consultation.jpg" />
          </div>
        </div>
        <div className="evenProcess">
          <div className="processText">
            <Typography variant="h4" component="h4">
              2. Design
            </Typography>
            <Typography variant="body1" component="p">
              We will present you with an offer to design you project, including structural engineering (if required), for a nominal fee, based on the “Target Budget” range developed from information provide at the initial consultation. This does not commit you to building with us. Over the course of several meetings, we will complete a full set of “Permit Ready” building plans.
            </Typography>
          </div>
          <div className="processImg">
            <img src="https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/design_image.jpg" />
          </div>
        </div>
        <div className="oddProcess">
          <div className="processText">
            <Typography variant="h4" component="h4">
              3. Contract
            </Typography>
            <Typography variant="body1" component="p">
              Using the “Final Plans” developed during the “Design” phase, we will price out the project accordingly, and offer a “Fixed Price Contract” to build your turn-key project. The Fixed Price Contract, limits unanticipated additional expense or surprises.
            </Typography>
          </div>
          <div className="processImg">
            <img src="https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/contract.jpg" />
          </div>
        </div>
        <div className="evenProcess">
          <div className="processText">
            <Typography variant="h4" component="h4">
              4. Permits
            </Typography>
            <Typography variant="body1" component="p">
              Following the execution of the Building Contract, we will submit the plans, and process all necessary permits and inspections required for your project. You will be given access to all of those important records.
            </Typography>
          </div>
          <div className="processImg">
            <img src="https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/permit.jpg" />
          </div>
        </div>
        <div className="oddProcess">
          <div className="processText">
            <Typography variant="h4" component="h4">
              5. Fixtures
            </Typography>
            <Typography variant="body1" component="p">
              As a “Custom Builder”, we allow you to select ANY fixtures & finishes for your project. We do, however, provide you with a list of “Preferred Vendor’s” of very experienced and knowledgeable industry representatives, with whom we have longstanding relationships, in order to streamline the process.
            </Typography>
          </div>
          <div className="processImg">
            <img src="https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/fixtures.jpg" />
          </div>
        </div>
        <div className="evenProcess">
          <div className="processText">
            <Typography variant="h4" component="h4">
              6. The Project
            </Typography>
            <Typography variant="body1" component="p">
              Procedures, processes, and paperwork is how we produce an extremely well managed project, and DELIVER ON TIME! During the project we will have regularly scheduled weekly meetings, as well as specific “Phase” meetings, to ensure clear communication throughout the project. The workers on your project are all longstanding members of the “Team”, and are highly skilled, extremely knowledgeable, and well trained in their respective fields. We all share the same goal: To deliver the highest quality product in the most timely fashion, on budget, and with another satisfied customer added to our reference list.
            </Typography>
          </div>
          <div className="processImg">
            <img src="https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/project.jpg" />
          </div>
        </div>
      </div>
    </div>
   
  );
}

export default Processes;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import ErrorPage from "./ErrorPage/Error";
import Home from './routes/Home/Home';
import CustomHomes from './routes/Services/CustomHomes/CustomHomes';
import HomeAdditions from './routes/Services/HomeAdditions/Additions';
import HomeRemodels from './routes/Services/HomeRemodels/Remodels';
import Kitchens from './routes/Services/Kitchens/Kitchens';
import Bathrooms from './routes/Services/Bathrooms/Bathrooms';
import Specialty from './routes/Services/Specialty/Specialty';
import Videos from './routes/Videos/Videos';
import Process from './routes/Process/Process';
import Contact from "./routes/Contact/Contact";
import Gallery from "./routes/Gallery/Gallery";
import reportWebVitals from './reportWebVitals';
import theme from './theme.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="CustomHomes" element={<CustomHomes />} />
          <Route path="HomeAdditions" element={<HomeAdditions />} />
          <Route path="HomeRemodels" element={<HomeRemodels />} />
          <Route path="Kitchens" element={<Kitchens />} />
          <Route path="Bathrooms" element={<Bathrooms />} />
          <Route path="SpecialtyProjects" element={<Specialty />} />
          <Route path="Gallery" element={<Gallery />} />
          <Route path="Process" element={<Process />} />
          <Route path="Videos" element={<Videos />} />
          <Route path="Contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

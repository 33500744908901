import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { 
  Button,
  Card, 
  CardContent, 
  CardMedia, 
  CardActionArea, 
  Paper, 
  Typography } from '@mui/material';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import Nav from '../../Nav/Nav.tsx';
import Banner from '../../Banner/Banner';
import Processes from '../Process/Processes';
import Footer from '../../Footer/Footer';
import './Home.css';

const services = [
  {
    name: 'Custom Homes',
    image: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/custom_homes.jpg',
  }, 
  {
    name: 'Home Remodels',
    image: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/outside_small.jpg',
  }, 
  {
    name: 'Home Additions',
    image: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/poptop_main.jpg',
  }, 
  {
    name: 'Bathrooms',
    image: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/shower.jpg',
  }, 
  {
    name: 'Kitchens',
    image: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/main_kitchen.jpg',
  }, 
  {
    name: 'Specialty Projects',
    image: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/basement_main.jpg',
  }, 
];

const Home = () => {
  const items = [
    {
      description: "custom home design and build",
      content: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/outsidefinishedfull.jpg',
    },
    {
      description: "custom home remodels",
      content: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/interior_remodel_front1.jpg',
    },
    {
      description: "custom baths, kitchens, and more!",
      content: 'https://s3.us-east-2.amazonaws.com/advantagebuildersofva.com/ABimages/main/sliderspecial.jpg',
    },
]

  return (
    <>
      <Nav />

      {/* Landing Carousel */}

      <header>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible
        >
          {
              items.map( (item, i) => <Item key={i} item={item} /> )
          }
        </Carousel>
      </header>

      {/* Feature section */}

      <div className="feature">
        <div className="featureLeft">
          <Typography variant="h3" component="h3">
          Advantage Builders, Inc.
          </Typography>
          <Typography variant="h5" component="h5">
            Virginia Class A Licensed Contractor
          </Typography>
          <Typography variant="body2" component="p">
          View one of our most recent projects from start to finish!
          </Typography>
        </div>
        <div className="featureRight">
          <iframe width="640" height="360" src="https://www.youtube-nocookie.com/embed/kx6LHgVlNBU?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
      </div>

      {/* Services Section */}

      <div className="services">
        <div className="servicesHeader">
          <Typography variant="h3" component="h3">
            Building for the future with skills from the past
          </Typography>
          <Typography variant="body1" component="p">
            We know the importance of your home and in today's lifestyle, custom homes with a personal touch have become the signature of fine living. We hope you enjoy this sampling and look forward to you contacting us about your next project.
          </Typography>
        </div>
        <div className="servicesCards">
          {services.map((service) => {
            return (
              <Card sx={{ maxWidth: 345, minWidth: 200, margin: '2rem' }}>
              {/* <CardActionArea
                href={`/${service.name.split(" ").join("")}`}
              > */}
                <CardMedia
                  component="img"
                  height="auto"
                  image={service.image}
                  alt={service.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div">
                    {service.name}
                  </Typography>
                </CardContent>
              {/* </CardActionArea> */}
            </Card>
            )
          })}
        </div>
      </div>

      {/* Process Section */}

      <Processes />

      {/* Call to action */}

      <Banner />

      {/* Footer */}
      <Footer />
    </>
  );
};

function Item(props)
{
    return (
        <Paper>
            <div className="galleryImages" style={{
              backgroundImage: `url(${props.item.content})`,
            }}>
              <div className="carouselCaption">
                <Typography variant="h4" component="h4">Advantage Builders, Inc.</Typography>
                <Typography variant="h5" component="h5">{props.item.description}</Typography>
              </div>
            </div>
            {/* <img src={props.item.content} alt={props.item.description} className="galleryImages" /> */}
        </Paper>
    )
}

export default Home;
import React from 'react';
import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import Nav from '../../../Nav/Nav.tsx';
import Banner from '../../../Banner/Banner';
import Footer from '../../../Footer/Footer';
import { itemData } from './imageList';
import '../Services.css';


const Kitchens = () => {
  return (
    <>
      <Nav />
      <div className="servicePage">
        <Typography variant="h3" component="h3">
          Kitchens
        </Typography>
        <Typography variant="body1" component="p">
          We hope you enjoy this sampling and look forward to you contacting us about your next project.
        </Typography>
        <Box sx={{ width: '80%', height: 'auto', margin: '0 auto' }}>
          <ImageList variant="masonry" cols={3} gap={8}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </div>
      <Banner />
      <Footer />
    </>
  );
};

export default Kitchens;
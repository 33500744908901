import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Alegreya SC',
          letterSpacing: '.1rem',
        },
        h3: {
          fontSize: '2.5rem',
        },
        h4: {
          fontSize: '2rem',
        },
        body1: {
          // fontFamily: 'Cinzel Decorative',
          fontSize: '1.2rem',
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Alegreya SC',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Alegreya SC',
        }
      }
    },
  },
});
export default theme;
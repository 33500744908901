import React from 'react';
import { Typography } from '@mui/material';
import Nav from '../../Nav/Nav.tsx'
import Footer from '../../Footer/Footer';
import Banner from '../../Banner/Banner';
import './Videos.css';


const Videos = () => {
  return (
    <>
      <Nav />
      <div className="videos">
        <Typography variant="h3" component="h3">
          Videos
        </Typography>
        <Typography variant="body1" component="p">
          We hope you enjoy this sampling and look forward to you contacting us about your next project.
        </Typography>
        <div className="videoSection">
          <Typography variant="h4" component="h4">
            Watch us remove a roof in a day!
          </Typography>
          <iframe  src="https://www.youtube.com/embed/Np9DzfuNAtc" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          <iframe src="https://www.youtube.com/embed/8jUVrw5mWGY" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
        <div className="videoSection">
          <Typography variant="h4" component="h4">
           Watch us build custom homes! 
          </Typography>
          <iframe  src="https://www.youtube.com/embed/18ouD220aMc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe src="https://www.youtube.com/embed/-4qvcyus5ms" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="videoSection">
          <Typography variant="h4" component="h4">
            Watch us remodel and renovate!
          </Typography>
          <iframe  src="https://www.youtube.com/embed/B8pPIOeRTOY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  src="https://www.youtube.com/embed/jyrmsIlHspM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  src="https://www.youtube.com/embed/kx6LHgVlNBU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe  src="https://www.youtube.com/embed/UxMsb1GqEZA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <Banner />
      <Footer />
    </>
  );
};

export default Videos;
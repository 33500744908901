import React from 'react';
import Nav from '../../Nav/Nav.tsx';
import Processes from './Processes';
import Banner from '../../Banner/Banner';
import Footer from '../../Footer/Footer';


const Process = () => {
  return (
    <>
      <Nav />
      <Processes />
      <Banner />
      <Footer />
    </>
  );
}

export default Process;